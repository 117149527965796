.own-select {
	position relative
	width 100%

	img {

		&.opened {
			transform rotate(180deg)
		}
	}

	select {
		opacity .25
		height 42px !important
		line-height 42px !important
		border-radius 0 !important
	}

	@media screen and (max-width: 576px) {
		display block
		max-width none
	}

	.select {
		display block
		text-align left
		color $color
		transition ease .2s
		font-size 14px
		border 1px solid $border
		border-radius 4px
		background $bglight
		height 42px
		line-height 42px
		color $black
		font-weight 500
		width 100%
		position relative

		&.disabled {
			pointer-events none
			opacity .75

			.arrow {
				display none
			}
		}

		&.hover {
			border 1px solid $border

			.arrow {
				transform translate(0, -60%) rotate(180deg)
			}
		}

		&:hover {
			cursor pointer
			border-color $border
		}

		.label {
			position absolute
			top 50%
			left .7rem
			transform translateY(-50%)
			line-height 20px
			color $color

			p {
				display none
			}
		}

		.arrow {
			position absolute
			top 50%
			right .5rem
			transform translateY(-55%)
			font-size 13px
			margin-top 3px
			color $black
			filter brightness(10000%)
			transition ease .2s
		}
	}

	.options {
		display none
		text-align left
		color $color
		background $bglight
		font-size 14px
		position absolute
		top calc(100% - 4px)
		left 0
		width 100%
		padding 0
		max-height 420px
		overflow-y scroll
		border-radius 0 0 4px 4px
		border 1px solid $border
		border-top none
		z-index 10

		&:-webkit-scrollbar {
			width: 1px;
		}
		
		&:-webkit-scrollbar-track {
			box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
		}
		
		&:-webkit-scrollbar-thumb {
			background-color: transparent;
			outline: 1px solid $primary;
		}

		a {
			display block
			color $black
			font-size 14px

			&:hover {
				text-decoration none
			}
		}

		&::-webkit-scrollbar {
			width 0
		}

		&::-webkit-scrollbar-track {
			width 0
			height 0
			-webkit-box-shadow inset 0 0 0 rgba(0, 0, 0, 0)
		}

		&::-webkit-scrollbar-thumb {
			width 0
			height 0
			background-color transparent
			outline none
		}

		li {
			// background $white
			// color $black
			list-style none
			transition ease .2s
			line-height 42px
			cursor pointer
			padding 0 calc(1rem - 4px)
			white-space nowrap

			&.no-match {
				display none
			}

			&:hover {
				background $bg
			}

			&.disabled {
				pointer-events none
			}

			&.highlighted {
				font-weight bold
				text-align center
				background $bg
				background $primary
			}

			&.search-icon {
				padding-bottom 3px
				
				&:hover {
					background $white
					cursor pointer
				}
			}
			
			.option-search {
				border: 1px solid $border;
				height: 40px;
				line-height: 40px;
				padding: 0 .5rem;
				border-radius: 3px;
				font-size: 14px;
				color: $color;
				width: 100%;
				margin-bottom: 0;
				margin-top: .5rem;
			}

		}
	}

	&.with-picture {
		.options {
			li {
				dflex(row, center, flex-start)
				line-height 1
				padding 1.25rem 1.9rem calc(.75rem + 1px)
				position relative

				div {
					white-space normal
				}

				&:after {
					content ''
					position absolute
					top calc(100% - 1px)
					left 1.9rem
					height 1px
					width calc(100% - 3.8rem)
					transition ease .2s
				}

				small {
					margin-bottom 0
				}

				img {
					width 28px
					margin-right .75rem
					margin-top -3px
					margin-bottom 3px
				}

				&:hover {
					background $primary

					&:after {
						background $primary
					}
				}

				&:last-of-type {
					&:after {
						content none
					}
				}
			}
		}
	}
}

.is-invalid {
	.own-select {
		.select {
			border-color $danger
			color $danger
		}
	}
}

.monthpicker--transform {
	@media screen and (min-width 577px) {
		transform translateY(-2px)
	}
}