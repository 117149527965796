.tooltip {
    position absolute
    top calc(100% + 0)
    right -20px
    min-width 200px
	color: $primary;
    background $black
    border-radius 5px
    transform translateY(25%)
    transition ease .2s
    pointer-events none
    z-index 3
    
    &:before {
        content ''
        position absolute
        top 0
        right 15px
        transform translateY(-100%)
        border-top 12px solid transparent
        border-right 12px solid transparent
        border-bottom 12px solid $black
        border-left 12px solid transparent
    }

    &--brighter {
        background $bglight

        &:before {
            border-bottom-color $bglight
        }
    }
    
    &__title {
        text-transform uppercase
        padding 10px 15px
        margin-bottom 0
        color $white
        font-weight 700
        font-style italic
    }
    
    &.is-shown {
        opacity 1
        transform translateY(0)
        pointer-events auto
    }
    
    &--center {
        left 50%
        right auto
        transform translate(-50%,25%)
        text-align center
        font-size 12px
        width auto
        min-width 0
        white-space nowrap
        
        .tooltip__title {
            padding 6px 15px
            font-size 11px
            text-transform none
        }
        
        &.is-shown {
            transform translate(-50%,0px)
        }
        
        &:before {
            left 50%
            right auto
            transform translate(calc(-50% - 1px),-100%)
            border-width 8px
        }
        
    }
 
    &-container {
        position: relative;
    }
    
    ul {
        max-height 185px
        overflow-y scroll
        margin-bottom 0

        li {
            padding 10px 15px
            transition ease .2s
            overflow hidden
            
            &:hover {
                background $button-bottom-bg
            }
            
            .success {
                color $primary
                font-size 18px
                float right
                transform translateX(50px)
                opacity 0
                transition ease .2s
                margin-right -5px
            }
            
            &.is-added {

                .success {
                    transform translateX(0)
                    opacity 1
                }
                
            }
            
            a {
                display block
                color $white
                
                &:hover {
                    text-decoration none
                    color $link
                }
                
            }
            
            button {
                display block
                border none
                background transparent
                color $white
                padding 0
                cursor pointer
            }
            
            &.border-top {
                border-color #222 !important
            }

        }
        
    }
    
}
