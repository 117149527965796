.admin {
	padding-bottom 15px
	font-family 'DM Sans'
	
	.form-group {
		margin-bottom 15px
	}
	
	.table {

		&::-webkit-scrollbar {
			width 2px
			height 2px
		}

		&::-webkit-scrollbar-track {
			background rgba(#000000,.25)
		}

		&::-webkit-scrollbar-thumb {
			background $primary
		}

		&::-webkit-scrollbar-thumb:hover {
			background darken($primary,20%)
		}
		
		tbody {
		    width 100%
		    display table
		    font-size 14px
		}

		tr {
			
			&.header {
				border-radius 2px 2px 0 0
				overflow hidden			
			}
			
			td {
				border none
				vertical-align middle
				white-space nowrap
				
				.btn {
					margin -3px 0 -5px					
				}

			}
			
			th {
				border none
				background-color $primary
				text-transform uppercase
				font-weight bold
				font-style italic				
				
				a {
					color $black
					text-decoration none !important
					dflex(row,center,flex-start)
					
					i {
						margin-left 5px
					}
					
				}
				
			}
			
			&:nth-child(2n+1) {
				background-color rgba($white,.05)
			}
			
		}

	}
	
	.image-container {
		dflex(row,center,flex-start)
		
		.image {
			width 40px
			height 40px
			border-radius 2px
			background-position center
			background-size cover
			margin-right .5rem
			cursor pointer
		}
		
	}
	
	.note-editing-area,
	.note-editor.note-frame.card {
		min-height 200px
	}
	
	.note-editable.card-block {
		min-height 200px
	}
	
	.pagination {

		.page-item {
			border none
			
			&.active {

				.page-link {
					background $primary
					color $white
					border-radius 2px
	
					&:hover {
						background darken($primary,20%)
						color $white
					}
					
				}
				
			}

			.page-link {
				background transparent
				color rgba($white,.75)
				border none
				outline none !important
				box-shadow none !important
				transition ease .2s
				text-decoration none
				
				&:hover {
					color $primary
				}

			}
			
		}

	}
	
	.ul-flex {
		width auto

		li {
			transition ease .2s
			dflex(row,center,space-between)
			padding .5rem 15px
			margin-left -15px
			width calc(100% + 30px)
			
			.btn {
				margin-left 2rem
			}
			
			&:hover {
				background-image: linear-gradient(to right, rgba($white,.025), rgba($white,.1));
			}

		}

	}

}

@media (min-width: 1200px) {
	
	#imageModel .modal-dialog {
	    max-width: 750px;
	    margin: 1.75rem auto;
	}

}

.page-content {

	&--admin {
		display grid
		grid-template-columns: 300px auto;
		min-height 100vh
		
		.sidebar-container {
			grid-column-start 1
			grid-column-end 2
			
			.sidebar {
				padding-top 80px
				height 100%
				color $white
				text-align left
	
				ul {
					
					li {
						text-align left
					}

				}

				.accordion,
				a {
					color $white
					text-align left
				}
				
				.accordion {

					i {
						float right
					}
					
				}
				
			}

		}
		
		.main-content {
			grid-column-start 2
			grid-column-end 3
			max-width calc(100vw - 300px)
			
			h1:before,
			h1:after {
				content none
			}

		}

	}
	
}