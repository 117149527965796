* {
	outline 0 !important
}

html {
	background: $body-bg;
	background-color: $body-bg;
	font-family 'DM Sans'
	fluid-type(14px,16px)
}

body {
	font-family 'DM Sans'
	background: $body-bg;
	background-color: $body-bg;
	color $color
}

.auth-form {
	margin-left -60px

	@media screen and (max-width 756px) {
		margin-left 0
	}
}

.grid {
	display: grid;
	grid-template-columns: 100px auto;
	grid-template-rows: 60px auto 60px;
	grid-template-areas: "header header" "sidebar main" "sidebar footer";
	
	@media screen and (max-width 756px) {
		grid-template-columns: 0 auto;
		grid-template-rows: 52px auto 52px;
		grid-template-areas: "header header" "sidebar main" "sidebar footer";
	}

	&--no-sidebar {
		grid-template-columns: auto;
		grid-template-rows: 60px auto 60px;
		grid-template-areas: "header" "main" "footer";
	}
	
	.navbar {
		grid-area header
	}
	
	.sidebar {
		grid-area sidebar
		min-height calc(100vh - 60px)
	}
	
	.page-content {
		grid-area main
		max-width 100%
		
		@media screen and (max-width 756px) {
			max-width 100vw
		}

	}
	
	footer {
		grid-area footer	
	}

}

.pe-none {
	pointer-events none
}

.text-underline {
	text-decoration underline
}

.page-content {
}