.nav-tabs {
	border-bottom 1px solid $borderlight

	.nav-item {

		.nav-link {
			border none
			color $color
			border-radius 4px 4px 0 0
			
			&.active {
				background $bglight
			}

		}

	}

}

.tab-content {
	padding 30px 0 20px 0
	box-shadow 5px 0 15px 0 rgba(10,20,10,.05)

	@media screen and (max-width 576px) {
		padding 0
	}
}