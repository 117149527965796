.sidebar {
	background $bg
	border-right 1px solid $borderlight
	padding 1.1rem 1rem
	text-align center
	overflow hidden
	
    @media screen and (max-width 756px) {
		padding 0
        position: fixed;
        top: 52px;
        left: 0;
        transform: translateX(-110%);
        z-index: 101;
        border-right: 1px solid #2D3747;
        height: calc(100vh - 52px);
		width 90vw
		text-align left
		transition ease .3s
		
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 100%;
			width: 100vw;
			height: 100vh;
			pointer-events: none;
			background: rgba(44, 55, 71, 0);
			transition: ease 0.4s;
		}
		
		&.is-opened {
			transform: translateX(0);
		}
		
		&.is-opened:after {
			background: rgba(44, 55, 71, 0.9);
			pointer-events: auto;
		}
	}


	ul {

		li {
			text-align center

			a {
				@media screen and (max-width 756px) {
					padding 10px 15px
				}
			}
			
			.profile__image {
				display block
				margin 0 auto 1rem

				@media screen and (max-width 756px) {
					margin-left 0
					margin-right 10px
					margin-bottom 0
				}
			}
			
			@media screen and (max-width 756px) {
				margin-bottom 0
			}

			&.normal-link {
				border-top 1px solid $borderlight
				padding-top 15px
				padding-bottom 15px

				@media screen and (min-width 757px) {
					display none
				}

				a {
					color $color
				}
			}

			&.logout {
				border-top 1px solid $borderlight
				padding-top 20px

				@media screen and (min-width 757px) {
					display none
				}

				button {
					background transparent
					text-align left
					color $color
					border none
				}
			}
			
		}
		
	}
	
}
