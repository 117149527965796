.hamburger {
	opacity 1!important
	padding 15px 13px 15px 15px
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
	border-radius 0
	background $primary
	height 2px
	width 38px
}

.navbar {
	height 60px
	background $bg
	border-bottom: 1px solid $borderlight

	@media screen and (max-width 576px) {
		height 52px
	}

	&-brand {
		padding 0
		dflex(row, center, flex-start)

		h2 {
			color $white
		}

		img {
		    height: 32px;
    		margin-left: 17px;
			margin-right 35px
    		
			@media screen and (max-width 768px) {
				margin -10px 0 0 -5px
			}

			@media screen and (max-width 576px) {
				height 38px
				margin 0px 10px 0 -2px
			}

		}
		
	}

	&-toggler {
		display none
	}

	&-collapse {

		.nav-item {
			color $white
			cursor pointer
			display flex
			align-items center

			&--light {
				color $border
			}

			.nav-link {
				color $white
				font-size 16px
			    // font-family: 'Montserrat', sans-serif;
			    font-weight: 700
			    text-transform uppercase
			    font-style italic
			    padding-left 0
			    padding-right 2rem
			    position relative;
			    text-align left
			    
			    &:before {
			        position absolute
			        bottom 0
			        left 0
			        content ''
			        width 0%
			        height .4em
			        background $primary
			        z-index -1
			        transition ease .2s
			    }
				
				&:hover {

					&:before {
						width 75%
					}
					
				}

			}

		}
		
		button {
			background transparent
			border none
			padding 0
			color $white
			font-size 18px
		    // font-family: 'Montserrat', sans-serif;
		    font-weight: 700
		    text-transform uppercase
		    font-style italic
		}

	}

	.tooltip-container {
		margin-right 15px
		margin-top 4px
		z-index 1
		transition ease .2s
		cursor pointer
		
		@media screen and (max-width 576px) {
			bottom .5rem
			right 1.4rem
		}
		
		i {
			color $white
			font-size 28px
		}
		
		.tooltip {
			right -18px
		} 

	}	

}
.toggler {
	position: fixed;
	top: 15px;
	right: 15px;
	z-index: 130;
	cursor: pointer;
	height: 20px;
	width: 30px;
	display: none;

	@media screen and (max-width 756px) {
		display block	
	}
}
.toggler span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	height: 2px;
	width: 30px;
	background: #F7FAFC;
	display: block;
	transition: ease 0.4s;
}
.toggler span:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -7px);
	height: 2px;
	width: 30px;
	background: #F7FAFC;
	transition: ease 0.4s;
}
.toggler span:before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 7px);
	height: 2px;
	width: 30px;
	background: #F7FAFC;
	transition: ease 0.4s;
}
.toggler.is-active span {
	width: 0;
}
.toggler.is-active span:after {
	transform: translate(-50%, 0) rotate(45deg);
}
	.toggler.is-active span:before {
	transform: translate(-50%, 0) rotate(-45deg);
}