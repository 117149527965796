.profile {
	position relative
	display block

	@media screen and (max-width 756px) {
		dflex(row, center, flex-start)
	}

	span {
		&.mobile {
			color $white
			text-align left
			display none
			
			@media screen and (max-width 756px) {
				display block
			}
		}
	}

	&:hover {
		text-decoration none
	}

	&__image {
		position relative
		z-index 1
		background-position center
		background-size cover
		background-color $bglight
		border-radius 100px
		border 2px solid $bglight
		color $color
		font-size 18px
		font-weight 600
		line-height 46px
		overflow hidden
		
		&--xs {
			width 42px
			height 42px
		}

		span {
			position absolute
			top 50%
			left 50%
			transform translate(-50%,-50%)

			top 0
			left 0
			width 100%
			height 100%
			background rgba($bglight,.5)
			line-height 38px
			transform none

			@media screen and (max-width 756px) {
				display none
			}
		}
	}	

	&.is-active {
		@media screen and (max-width 756px) {
			background rgba($bglight,.5)
		}
		
		&:before {
			content ''
			position absolute
			top 0
			left 50%
			width 200px
			display block
			height 100%
			background $bglight
			z-index 0

			@media screen and (max-width 756px) {
				content none
			}
		}

	}

}

.user-table {
	width 100%

	thead {
		display table
		width 100%
	}	
	
	tbody {
		display table
		width 100%
		
		tr {

			&:hover {
				background $bglight
			}

		}
		
	}

	tr {
		border-bottom 1px solid $borderlight
		transition ease .2s
		
		&:last-of-type {
			border none
		}

		&.border-top {
			border-top 4px solid $borderlight!important
			border-bottom none
			background rgba($borderlight, .5)
		}

		&.bg-grey {
			background rgba($borderlight, .5)			
			td {
				vertical-align bottom
			}
			i {
				margin-right 10px
				width 26px
				color #fdb827
				font-size 20px
				
				&.fa-money-bill-alt {
					color $primary
					font-size 19px
				}
			}
		}

		&.payment-card {
			background rgba($bglight, .25)

			.name {
				position relative
				z-index 1
			}
		}

		&.deleted-row {
			display none
			opacity 1
			color $danger

			a {
				color $danger
			}

			.delete {
				form {
					display none
				}
			}
		}
		
		th,
		td {
			padding .5rem
			white-space nowrap

			&.wrap {
				white-space pre-wrap

				@media screen and (max-width 576px) {
					display none
				}
			}
			
			small {
				font-style italic
				opacity .8
			}

			em {
				opacity .75
				font-weight 400
				font-style normal
				font-family 'Dm Sans'!important
			}
			
			b {
				font-weight bold
			}

			span:not(.name) {
				display block
				font-size 75%
				opacity .5
			}

			&.delete {
				padding-right 15px
				
				a {
					color $danger
				}

			}
			
			&.edit {
				
				a {
					color $success
				}

			}
			
		}
		
		td {
			vertical-align middle
		}
		
		th {
			font-weight bold
		}
		
	}
	
}