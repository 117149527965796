.info {
    position absolute
    top -100%
    left 50%
	color: $white;
    font-size 12px
    font-family 'DM Sans'
    font-weight 300
    background $black
    border-radius 5px
    white-space nowrap
    transform translate(-50%, -15px)
    opacity 0
    transition ease .2s
    pointer-events none
    z-index 3
    font-style normal
    padding 5px 10px

    &:before {
        content ''
        position absolute
        top 100%
        left 50%
        transform translateX(-50%)
        border-top 10px solid $black
        border-right 10px solid transparent
        border-bottom 10px solid transparent
        border-left 10px solid transparent
    }

    &-box {
        position relative

        .trigger {
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            cursor pointer
        }
    }

    &--right {
        right -7px
        left auto
        transform translate(0, -15px)
    
        &:before {
            content ''
            position absolute
            top calc(100% - 0px)
            right 5px
            left auto
            transform translateX(0)
            border-top 8px solid $black
            border-right 8px solid transparent
            border-bottom 8px solid transparent
            border-left 8px solid transparent
        }

    }

    &-container {
        position absolute
        top -3px
        right -12px
        color $info

        &:hover {
            .info {
                transform translate(-50%, -22px)
                opacity 1

                &--right {
                    transform translate(0, -22px)
                }
            }
        }
    }

    &-box {
        color $white
        margin-right 15px
        dflex(row, center, flex-start)

        label {
            margin-bottom 0
            margin-top 6px
            margin-right 10px
        }
    }
}

.hidden-info {
    .info-container {
        display none
    }
}