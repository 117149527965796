.pagination {
	margin 15px 0

	.page-item {
		border none
		
		&.active {

			.page-link {
				background $bglight
				color $color
				border-radius 4px

				&:hover {
					color $link
				}
				
			}
			
		}

		.page-link {
			background transparent
			color $color
			border none
			outline none !important
			box-shadow none !important
			transition ease .2s
			text-decoration none
			
			&:hover {
				color $link
			}

		}
		
	}

}
