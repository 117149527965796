.modal {
	z-index 1070
	
	.modal-dialog {
		
		.modal-content {
			background-color $body-bg
			
			.modal-header {
			}

			.modal-body {
			}

			.modal-footer {
			}
			
		}
		
	}
	
}