footer {
	margin-top 15px
	height 50px
	border-top 1px solid $borderlight
	font-size 10px
	dflex(row, center, center)
	color #4A5B75
	text-align center

	@media screen and (max-width 756px) {
		height 52px
	}
}