/**
 * Custom button styles
 */
colors = 'primary' $primary, 'secondary' $bglight, 'facebook' $facebook, 'success' $success, 'danger' $danger, 'transparent' transparent

.btn {
	display inline-block
	text-align center
	white-space nowrap
	vertical-align middle
	user-select none
	border 1px solid transparent
	font-size 16px
	height 42px
	line-height 42px
	padding-top 0
	padding-bottom 0
	padding-left 2rem
	padding-right 2rem
	border-radius 4px
	color $color
	// font-family: 'Montserrat', sans-serif;
	font-weight 500
	// text-transform uppercase
	cursor pointer
	transition color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out

	@media screen and (max-width 576px) {
		height 44px
		line-height 44px
		font-size 14px
	}

	&:hover, &:focus {
		text-decoration none
	}

	&:focus, &.focus {
		outline 0
		box-shadow none
	}

	&:disabled, &.disabled {
		opacity .5
		box-shadow none
		pointer-events none
	}

	&:not([disabled]):not(.disabled) {
		cursor pointer
	}

	// FOREACH COLORS AND GENERATE BUTTONS AND OUTLINE BUTTONS
	for color in colors {
		&-{color[0]} {
			color $color
			background-color color[1]
			border-color color[1]

			&:hover {
				color $color
				background-color darken(color[1],10%)
				border-color darken(color[1],10%)
			}

			&:focus, &.focus {
				background-color darken(color[1],20%)
				border-color darken(color[1],20%)
				box-shadow none
			}

			&.disabled, &:disabled {
				color $color
				background-color color[1]
				border-color color[1]
			}

			&:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
				background-color darken(color[1],20%)
				border-color darken(color[1],20%)
				box-shadow none
			}

			&:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
				background-color darken(color[1],20%)
				border-color darken(color[1],20%)
				box-shadow none
			}
		}

		&-{color[0]}-outline {
			color color[1]
			background-color transparent
			border-color color[1]
			border-width 2px

			&:hover {
				color $color
				background-color color[1]
				border-color color[1]
			}

			&:focus, &.focus {
				box-shadow none
			}

			&.disabled, &:disabled {
				color color[1]
				background-color $buttoncolor
				border-color color[1]
			}

			&:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
				color $black
				background-color color[1]
				border-color color[1]
			}

			&:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
				box-shadow none
			}
		}
	}

	&-xs {
		font-size 14px
		height 30px
		line-height 30px
	}
	
	&-block {
		display block
		padding-left 15px
		padding-right 15px
		width 100%
	}
	
	&-clear {
		background transparent
		border none
		padding 0
		text-transform none
		line-height 1
		font-weight 400
		font-size 16px
		
		&:hover {
			color $primary
		}

	}
	
	&-facebook {
		// color $white
	}
	
	&-random {
		dflex(row,center,center)
		margin 15px 15px 15px 15px
		width 100%
		
		.own-number {
			margin-left 15px
			dflex(row,center,center)

			input {
				width 26px
				height 26px
				margin 0 10px
				border none
				background transparent
				text-align center
				font-weight 400
				color $buttoncolor
			}
			
			.mod {
				width 20px
				height 20px
				background rgba($black,.25)
				transition ease .2s
				border-radius 2px
				dflex(row,center,center)
				cursor pointer
				
				&:hover {
					background rgba($black,.75)
				}
				
				i {
					font-size 12px
					pointer-events none
				}
			}
			
		}

	}
	
	&-close {
		width 50px
		min-width 50px
		font-size 18px
	}
	
	&-animate {
		position relative
		overflow hidden

		.clicked {
			position absolute
			top 50%
			left 50%
			transform translate(-50%,-150%)
			transition ease .3s
			opacity 0
			font-size 18px
		}
		
		.default {
			opacity 1
			display block
			transition ease .3s
		}
		
		&.is-clicked {

			.clicked {
				opacity 1
				transform translate(-50%,-48%)
			}

			.default {
				opacity 0
				transform translateY(100%)
			}
			
		}
	
	}
	
	&-xs-block {
		@media screen and (max-width 576px) {
			width 100%
		}
	}

}

button {
	position relative
	cursor pointer
	
	&.danger {
		background transparent
		border none
		color $danger
	}
}

.bottom-btn-container {
	position fixed
	bottom 0
	left 275px
	z-index 10
	background $button-bottom-bg
	width calc(100% - 275px)
	padding 0 15px
	box-shadow 0 0 15px 0 rgba($black, .25)
	dflex(row,center,center)
	transition .4s
	
	@media screen and (max-width 992px) {
		width 100%
		left 0
	}
	
	&--running {
		transform translateY(150%)
	}
	
	.btn {
		max-width 300px
		margin 15px 5px
		
		@media screen and (max-width 576px) {
			max-width 100%
		}
		
		&:nth-child(1) {
			margin-left 0
		}

		&:nth-child(2) {
			margin-right 0
		}

	}
	
}

.exercise-list-container {

	&.session-on {

		.bottom-btn-container--default {
			transform translateY(150%)
		}
		
		.bottom-btn-container--running {
			transform translateY(0)
		}
		
	}

}

.js-switch-label {

	span {
		display none
		
		&.default {
			display block
		}
		
	}
	
}

// Specificity overrides
input[type='submit'], input[type='reset'], input[type='button'] {
	&.btn-block {
		width 100%
	}
}
