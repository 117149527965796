form {

	a:not(.btn) {
		color $link
		text-decoration underline
	}

}

.auth-form {
	color $color

	form {
		padding 15px 1.875rem
		
		@media screen and (max-width 576px) {
			padding 15px 15px 1.5rem 15px
			margin 0 -15px
		}
		
		p {
			margin-bottom 0
		}
		
	}
	
}

.form-group {
	position relative
	margin-bottom 15px

	&.hidden-input {
		display none

		&.is-shown {
			display block
		}
	}
	
	label {
		margin-bottom .25rem
		font-size 14px
	}

	.small-link {
		display block
		text-align right
		color $link
		font-size 10px
		font-weight 500
		line-height 1.3		
		position absolute
    	top 0
    	right 0
    	transform translateY(-100%);
	}
	
	&:before {
		content attr(data-placeholder)
		position absolute
		top 8px
		left .8rem
		font-size 9px
		color $color
		transition ease .2s
		transform translateY(-100%)
		opacity 0
	}
	
	&.is-focused {
		
		&:before {
			transform translateY(-4px)
			opacity 1
		}

	}
	
	.error-text {
		font-size 10px
		color $danger
		text-align right
	}
	
	&--admin-search {
		position relative

		.clear {
			position absolute
			top 50%
			right 0
			transition ease .4s
			transform translate(50%,-50%)
			opacity 0
			color $danger
			font-size 24px
			cursor pointer
			padding-right 1rem
			padding-left 1rem	
			
			&.is-shown {
				transform translate(0,-50%)
				opacity 1
			}		
		
		}
	}
	
	&--search {
		position relative

		.search {
			border-radius 100px
			box-shadow none
			border 1px solid $black
			height 32px
			padding-left 15px
			padding-right 15px
			font-size 14px
		}
		
		.close {
			position absolute
			top 50%
			right 15px
			transform translateY(-50%)
			font-size 16px
			opacity .25
			cursor pointer
			transition ease .2s
			
			&:hover {
				opacity 1
			}
			
		}

	}
	
	&--file {
		position relative
		
		input[type=file] {
			opacity 0
			z-index 0
		}
		
		.trigger {
			line-height 1.5
			text-align left
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			z-index 1
			font-size 14px
			border 1px solid $border
			background $bglight
			color $color
			border-radius 4px
			height 42px
			line-height 42px
			font-weight 500
			padding 0 15px
			cursor pointer
		}

	}
	
	.image-container {
		position relative
		
		.delete {
			cursor pointer
			position absolute
			top 1rem
			right 1rem
			color $danger
			font-size 28px
		}
		
	}
	
	&--row {
		padding-right 15px
		dflex(row,center,space-between)
	}
	
	textarea {
		// opacity 0
		width 100%
		border 1px solid $border
		background $bglight
		color $color
		border-radius 4px
		padding 10px
	}
	
	select {
		opacity 0
		width 100%
		height 42px
		background $subcategory-bg
		display block
		border-color $subcategory-bg
		color $subcategory-bg
		font-size 14px
		border-left 3px solid $subcategory-bg
		border-radius 4px
	}
	
	&.is-invalid {

		.form-control {
			border-color $danger
		}
		
	}
	
	&--with-button {
		width 100%
		dflex(row,center,flex-start)
		
		.form-control {
			width 100%
			min-width 100%
		}
		
		img {
			display none
			cursor pointer
			margin-left 10px
			height 30px
			filter brightness(10000%)
		}
		
	}

}

.form-control {
	font-size 14px
	border 1px solid $border
	background $bglight
	height 42px
	line-height 42px
	color $color
	font-weight 500
	transition ease .2s
	border-radius 4px

	&:-webkit-autofill,
	&:-webkit-autofill:hover, 
	&:-webkit-autofill:focus, 
	&:-webkit-autofill:active{
		border 1px solid $border!important
		background $bglight!important
		color $color!important
	}


	&:active,
	&:focus {
		box-shadow none
		color $color
		background $bglight
		border-color $border
		height 42px
		line-height 42px
	}
	
	&:not(.form-control--no-anim) {

		&:active,
		&:focus {
			padding-top 10px
			padding-bottom 0
		}

	}

	&::-webkit-input-placeholder {
		color $border
		font-size 14px
	}

	&::-moz-placeholder {
		color $border
		font-size 14px
	}

	&:-ms-input-placeholder {
		color $border
		font-size 14px
	}

	&:-moz-placeholder {
		color $border
		font-size 14px
	}

	// hide IE clear and reveal buttons of input fields
	&::-ms-clear, &::-ms-reveal {
		display none
	}
}

/*
 * AUTOCOMPLETE BACKGROUND AND COLOR CHANGE
 */
@keyframes autofill {
	to {
		color $color
		background $white
		font-size  14px
	}
}

input:-webkit-autofill {
	-webkit-animation-name autofill
	-webkit-animation-fill-mode both
}

/*
 * HIDE NUMBER INPUT SPINNERS
 */
input[type=number] {
	// Firefox:
	-moz-appearance textfield

	// Webkit:
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		-webkit-appearance none
		margin 0
	}
}

/**
 * Custom Radio button
 */
[type='radio']:checked, [type='radio']:not(:checked) {
	position absolute
	left -9999px
}

[type='radio']:checked + label, [type='radio']:not(:checked) + label {
	position relative
	padding-left 28px
	cursor pointer
	line-height 12px
	display inline-block
	color $placeholder
	font-size 14px

	a {
		font-size 14px
		color $color
		text-decoration underline
		margin-bottom 0
	}

}

[type='radio']:checked + label:before, [type='radio']:not(:checked) + label:before {
	content ''
	position absolute
	left 0
	top -2px
	width 15px
	height 15px
	border 1px solid $placeholder
	background $white
	border-radius 15px
}

[type='radio']:checked + label:after, [type='radio']:not(:checked) + label:after {
	content ''
	position absolute
	left 3px
	top 1px
	background $black
	width 9px
	height 9px
	border-radius 9px
	transition all .2s
	font-family Helvetica, Arial, sans-serif
}

[type='radio']:not(:checked) + label:after {
	opacity 0
	-webkit-transform scale(0)
	transform scale(0)
}

[type='radio']:checked + label:after {
	opacity 1
	-webkit-transform scale(1)
	transform scale(1)
}

/**
 * END Custom radio button
 */

.note-editor.note-frame.card,
.note-editing-area {
	height 100%
}

.note-editor {
	border 1px solid $black !important

	.btn {
		background $black
		background-color $black
		
		&:hover {
			border-color transparent
			color $black
		}
		
	}

}

.card-header.note-toolbar,
.note-popover .popover-content {
	background $body-bg
}

.note-editable.card-block {
	background transparent!important
	color $white!important
	height 100%!important
}

.note-editing-area {
	background $black
}

.note-editor.note-frame .note-statusbar {
	background-color $body-bg
	border none
}

.ui-button,
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
html .ui-button.ui-state-disabled:active,
html .ui-button.ui-state-disabled:hover {
	background transparent
	border none
}

.ui-button.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
	background $primary
	border-color $primary
	// font-family 'Montserrat', sans-serif
	font-weight 300
} 

.ui-state-default {
	// font-family 'Montserrat', sans-serif
	font-weight 300
}
.ui-datepicker .ui-datepicker-header {
	color $color
	background $bglight
	border-color $border
}
.ui-widget.ui-widget-content {
	color $color
	background $bglight
	border-color $border
}