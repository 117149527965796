.filter {
    position relative
    width 100%
    height auto
    margin-bottom 0
    padding-top 15px
    padding-bottom 15px

    @media screen and (max-width 756px) {
    }

    .container,
    .container-fluid {
        position relative
        z-index 1
    } 
}