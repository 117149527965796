@font-face {
    font-family: 'Gotham-Black';
    src:
        url('../fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Black.woff') format('woff'),
        url('../fonts/Gotham-Black.ttf')  format('truetype'),
        url('../fonts/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Bold';
    src:
        url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Bold.woff') format('woff'),
        url('../fonts/Gotham-Bold.ttf')  format('truetype'),
        url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Light';
    src:
        url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Light.woff') format('woff'),
        url('../fonts/Gotham-Light.ttf')  format('truetype'),
        url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
    fluid-type(14px,16px)
    color rgba($white,.75)
    font-family 'DM Sans', system-ui
    font-weight 300    
    em {
        font-style italic;
    }

}

h3,
h4,
h5,
h6 {
    font-family 'DM Sans', system-ui
    font-weight: bold
    color $color
}

h1 {
    fluid-type(24px,32px)
    font-family 'DM Sans', system-ui
    font-weight: bold
    color $color

    small {
        text-transform lowercase
    }
}

h2 {
    fluid-type(18px,26px)
    font-family 'DM Sans', system-ui
    font-weight: bold
    color $color
    
    @media screen and (max-width 576px) {
        margin-bottom 0
    }

}

h1,
h2 {
    position relative
    z-index 1
    
    // &:before {
    //     position absolute
    //     bottom 0
    //     left 0
    //     content ''
    //     width 75%
    //     height .5em
    //     background $primary
    //     z-index -1
    // }
}

h3 {
    fluid-type(18px,28px)
    font-family 'DM Sans', system-ui
    font-weight: bold
    color $color
}

ol,
ul,
p {
    color $color
    font-family 'DM Sans', system-ui
    font-weight: 400
}

.font-xs {
    font-size 14px
}

.c-primary {
    color $primary
}

.c-primary a {
    color $color
    text-decoration underline
}

a.c-primary {
    color $color
    text-decoration underline
    
    &:hover {
        color $link
        text-decoration underline
    }

}

.c-success {
    color $success
}

.c-white {
    color $white
}

.c-light {
    color rgba($white,.5)
    
    a {
        color rgba($white,.5)
        transition ease .2s
        cursor pointer
        
        &:hover {
            color $link
        }

    }

}